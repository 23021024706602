.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-wrap: wrap;
}

.categoryContainer{
    width: calc(100% - 275px);
    height: auto;
    display: block;
    padding-top: 25px;
    padding-bottom: 20px;
    overflow: auto;
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    position: sticky;
    top: 74px;
    background: #fafafa;
}

.navigation {
    width: calc(100vw - 270px);
    overflow-x: auto;
}

.listCategories {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px 0;
}

.category{
    display: flex;
    padding: 10px 25px;
    display: flex;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    cursor: pointer;
    margin: 0px 10px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
}

.activeCategory{
    background: #326dff;
    color: #fff;
}

.productContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.product{
    min-width: 300px;
    max-width: 25%;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.imgProduct{
    width: 100%;
    height: 215px;
    display: block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    text-align: center;

}

.row{
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    align-content: space-between;
    flex-wrap: wrap;
    margin: 10px;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.09);
}

.wraper {
    padding: 15px;
    /* display: flex; */
    flex-wrap: wrap;
    width: calc(100% - 30px);
    height: auto;
}

.wraper .item:nth-child(2) {
    max-height: 120px;
    height: 100%;
    overflow: hidden;
}

.wraper select {
    width: 100%;
    height: 40px;
    border: none;
    background: #f1efef;
}

.item {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
}

.item span {
    width: 100%;
    font-weight: 500;
    padding-bottom: 5px;
    font-size: 18px;
}

.description {
    height: 110px;
    max-width: 250px;
    width: 100%;
    overflow: hidden;
}

.button {
    color: white;
    width: 100%;
    border: 0;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    min-width: 180px;
    text-align: center;
    font-size: 16px;
    background: #326dff;
    margin-top: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.info{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
}

.languageButton{
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #326dff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.09);
    text-transform: uppercase;
    font-size: 17px;
    z-index: 9999;
}

.modalLanguage{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #ffffffb3;
    z-index: 9999;
}

.languageContainer{
    max-width: 350px;
    width: 100%;
    height: auto;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.language{
    width: 100%;
    height: 100%;
    margin: 15px;
    background: #fff;
    box-shadow: -2px 1px 47px -21px rgba(0,0,0,0.76);
    padding: 25px 30px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    position: relative;
}

.language span{
    width: 100%;
    height: auto;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.title{
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 10px 0px;
}

.activeLanguage{
    color: #326dff;
}

.close{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    background: #326dff;
    border-radius: 50%;
    cursor: pointer;
    background-image: url(../../../assets/img/close.svg);
    background-position: center;
    background-size: 12px;
    background-repeat: no-repeat;
}

.buttonWrappers {
   width: 100%;
}

.titleWrap {
    width: calc(100% - 0px);
    display: flex;
    justify-content: space-between;
}

.titleText {
    max-height: 110px;
    height: 100%;
    max-width: 250px;
    width: 100%;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .product{
        max-width: 33.33%;
    }
 }

 @media (max-width: 959px) {
    .navigation {
        width: calc(100vw - 32px);
    }
    .categoryContainer{
        width: calc(100% - 30px);
    }
    .productContainer {
        width: calc(100% - 30px);
    }
 }

 @media (max-width: 599px) {
    .product {
        margin: 0 auto;
    }
 }

@media (max-width: 540px) {
    .product{
        max-width: 100%;
    }
 }

 